<template>
  <div class="newsletter-component">
    <div class="content">
      <div class="filters">
        <div class="row align-items-center">
          <div class="col">
            <input type="text" style="max-width: 300px;" v-model="parameters.q" placeholder="Search email, name or phone" class="form-control" v-on:keyup.enter="getMarketingOptIns()" />
          </div>
          <div class="col-auto pe-0">
            <span class="pale">Order By</span>
          </div>
          <div class="col-auto">
            <a class="vave-btn" href="#" @click.prevent="showFilters=!showFilters">
              Filters
              &nbsp;
              <i class="fa-regular fa-filter-list"></i>
            </a>
          </div>
        </div>
        <div class="filters-box" :class="{'show': showFilters}">
          <div class="row pale">
            <div class="col-6 mt-2">
              Synchronised
              <select class="form-select" v-model="parameters.synchronisation_status">
                <option value="not_synchronised">Not Synchronised</option>
                <option value selected>All</option>
              </select>
            </div>
            <div class="col-6 mt-2" v-if="sources">
              Source
              <select class="form-select" v-model="parameters.source">
                <option value selected>Any</option>
                <option :value="source" v-for="source,k in sources" :key="k">{{source}}</option>
              </select>
            </div>
            <div class="col-6 mt-2">
              From
              <input class="form form-control" v-model="parameters.from" type="date" />
            </div>
            <div class="col-6 mt-2">
              To
              <input class="form form-control" v-model="parameters.to" type="date" />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col mt-3">
              <a href="#" class="blue" @click.prevent="()=>{resetFilters(); showFilters=false;}">Reset</a>
            </div>
            <div class="col-auto mt-3">
              <button class="vave-btn btn-small btn-green" @click="()=>{getMarketingOptIns(); getMarketingOptInsStats(); showFilters=false;}" :disabled="!marketingOptInsReady">Apply</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-if="Object.keys(appliedFilters).length">
        <div class="col">
          <div class="filters-applied">
            <div class="label">ACTIVE FILTERS:</div>
            <div class="pill" v-for="filter, key in appliedFilters" :key="'filt-'+key">
              {{key}}: {{filter}}
              <span @click="unsetFilter(key)" class="unset-filter">
                <i class="fas fa-times"></i>
              </span>
            </div>
            <a class="ps-2 blue" href="#" @click.prevent="resetFilters()">Reset</a>
          </div>
        </div>
      </div>

      <div class="row" v-if="!marketingOptInsReady">
        <div class="col">
          <div class="mt-4">
            <div class="loading-placeholder">
              <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="table-container mt-2" v-if="marketingOptInsReady">
            <table class="table align-middle">
              <thead class="head">
                <tr>
                  <th></th>
                  <th>Customer</th>
                  <th>Phone</th>
                  <th>Source</th>
                  <th class="ps-4 text-center">Opted In</th>
                  <th class="ps-4 text-center">Synched</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="entry in marketingOptInsList.data" :key="entry.id" :class="{'failedToSyncOrder': entry.sync_status == 'failed', 'retrying' : entry.sync_status == 'retrying', 'isToday': isToday(entry)}" style="height: 68px">
                  <td style="max-width: 150px;">
                    <div class="d-flex flex-column text-center align-items-center">
                    <span class="pale">{{ entry.created_at | formattedDate }}</span>
                    <span class="pale hidden small">{{ entry.created_at | formattedHour }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column">
                      <span class="bold blue">{{entry.first_name}} {{entry.last_name}}</span>
                      <span class="pale">{{entry.email}}</span>
                    </div>
                  </td>
                  <td> <span class="pale">{{entry.phone}}</span> </td>
                  <td>{{entry.source}}</td>

                  <td>
                    <div class="d-flex flex-column justify-content-center align-items-center" v-if="entry.opt_out">
                        <svg class="status-icon red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                          />
                        </svg>
                      <span class="pt-1 pale hidden small">{{ entry.opt_out | formattedLongDate }}</span>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center" v-else-if="entry.opt_in">
                      <svg class="status-icon green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                      </svg>
                      <span class="pt-1 pale hidden small">{{ entry.opt_in | formattedLongDate }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-column justify-content-center align-items-center" v-if="!entry.last_synchronised">
                      <svg class="status-icon yellow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
                      </svg>
                      <span class="pt-1 pale hidden small">Not Synchronised</span>
                    </div>
                    <div class="d-flex flex-column justify-content-center align-items-center" v-else>
                      <svg class="status-icon green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                      </svg>
                      <span class="pt-1 pale hidden small">{{ entry.last_synchronised | formattedLongDate }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <pagination class="paginator" :limit="4" align="center" :data="marketingOptInsList" @pagination-change-page="getMarketingOptIns"></pagination>
          </div>
        </div>
      </div>

      <div class="row mt-3" v-if="chartReady">
        <div class="col-4">
          <chart :colors="chartData.colors" height="250" :data="chartData" />
        </div>
        <div class="col-8">
          <div class="linechart-bg">
            <apexchart type="line" height="250" :options="subsChartOptions" :series="subsSeries"></apexchart> 
          </div>
        </div>
      </div>
      <div class="row mt-3" v-if="unfilteredChartReady">
        <div class="col-4">
          <chart :colors="unfilteredChartData.colors" height="250" :data="unfilteredChartData" />
        </div>
        <div class="col-8">
          <div class="linechart-bg">
            <apexchart type="line" height="250" :options="unfilteredSubsChartOptions" :series="unfilteredSubsSeries"></apexchart> 
          </div>
        </div>
      </div>
    </div>

    <div class="floating-summary" v-if="Object.keys(appliedFilters).length">
      <div v-if="filteredResults.totalOptins" class="label">
        <span class="value">{{filteredResults.totalOptins}}</span>
        Opted In
      </div>
      <div v-if="filteredResults.totalOptouts" class="label">
        &nbsp; and <span class="value">{{filteredResults.totalOptouts}}</span>
        Opted out
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import moment from "moment";
import pagination from "laravel-vue-pagination";
import VueApexCharts from "vue-apexcharts";
import Chart from "./dashboard/Chart.vue"

export default {
  data() {
    return {
      unfilteredSubsChartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Newsletter Subscriptions",
          align: "left",
        },

        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Day",
          },
        },
        yaxis: {
          title: {
            text: "# of new Subscriptions",
          },
          min: 0,
          max: 10,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      }, 
      unfilteredSubsSeries: [],
      
      subsChartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Filtered Newsletter Subscriptions",
          align: "left",
        },

        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Day",
          },
        },
        yaxis: {
          title: {
            text: "# of new Subscriptions",
          },
          min: 0,
          max: 10,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      }, 
      subsSeries: [],

      showFilters: false,
      sources: [],
      marketingOptInsList: {},
      marketingOptInsReady: false,
      filteredResults: {
        totalOptins: null,
        totalOptouts: null,
      },
      appliedFilters: {},
      perPage: 15,
      parameters: {
        q: "",
        synchronisation_status: "",
        source: "",
        from: "",
        to: "",
      },
      chartReady: false,

      unfilteredChartReady: false,
      chartData: {},
      unfilteredChartData: {},

      chartColours: {},
    };
  },
  components: {
    pagination,
    Chart,
    apexchart: VueApexCharts,
  },
  watch: {
    $route() {
      this.getMarketingOptIns();
    },
  },
  mounted() {
    if (!this.marketingOptInsList.data || this.marketingOptInsList.data.length <= 0) {
      this.getMarketingOptIns();
    }
    
    this.getMarketingOptInsStats(true);
  },
  filters: {
    money(value) {
      return (Math.round(value) / 100).toFixed(2);
    },
    formattedDate(value) {
      return value ? moment(String(value)).format("DD/MM/YY") : "";
    },
    formattedLongDate(value) {
      return value ? moment(String(value)).format("DD/MM/YY HH:mm") : "";
    },
    formattedHour(value) {
      return value ? moment(String(value)).format("HH:mm") : "";
    },
    diffForHumans(value) {
      return value ? moment(String(value)).fromNow() : "";
    },
    formatStatus(value) {
      return value.replace(/_/g, " ");
    },
  },
  methods: {
    getMarketingOptInsStats(unfilteredChart = false) {
      this.chartReady = false;

      var params = {};
      // filter empty values
      if (!unfilteredChart) {
          Object.keys(this.parameters).forEach((key) => {
            if (this.parameters[key] != "") {
              // this.parameters[key] is an array, implode by ,
            if (Array.isArray(this.parameters[key])) {
              params[key] = this.parameters[key].join(",");
            } else {
              params[key] = this.parameters[key];
            }
          }
        });
      }

      axios
        .get(
          "/api/admin/marketing-opt-ins/chart",
          {
            params: params,
          }
        )
        .then((response) => {
          if (unfilteredChart) {
            // cake unfiltered
            this.sources = Object.keys(response.data.total_per_source)
            let labels = this.sources.map((source) => source + " (" + response.data.total_per_source[source] + ")")
                        // build this.chartColours using the default colours and the sources
            let allColours = [
              "#775dd0",
              "#f94560",
              "#3be396",
              "#fbb019",
              "#3498db",
            ];
            this.chartColours = {}
            this.sources.forEach((source, index) => {
              this.chartColours[source] = allColours[index % allColours.length]
            })

            this.unfilteredChartData = {
              data: [labels, Object.values(response.data.total_per_source)],
              options: {
                title: 'Total Sources',
              },
              colors: Object.values(this.chartColours),
            }

            // line unfiltered
            this.unfilteredSubsChartOptions.yaxis.max = Math.max(5, ...Object.values(response.data.daily_subscriptions)) + 1;
            this.unfilteredSubsChartOptions.xaxis.categories = Object.keys(response.data.daily_subscriptions).reverse();
            this.unfilteredSubsSeries = [
              {
                name: "Subs",
                data: Object.values(response.data.daily_subscriptions).reverse(),
              },
            ];

            this.unfilteredChartReady = true;
            return;
          }

          // cake filtered
          let labels = Object.keys(response.data.total_per_source).map((source) => source + " (" + response.data.total_per_source[source] + ")")
          this.chartData = {
            data: [labels, Object.values(response.data.total_per_source)],
            options: {
              title: 'Filtered',
            },
            colors: Object.keys(response.data.total_per_source).map((source) => this.chartColours[source])
          }

          // line filtered
          this.subsChartOptions.yaxis.max = Math.max(5, ...Object.values(response.data.daily_subscriptions)) + 1;
          this.subsChartOptions.xaxis.categories = Object.keys(response.data.daily_subscriptions).reverse();
          this.subsSeries = [
            {
              name: "Subs",
              data: Object.values(response.data.daily_subscriptions).reverse(),
            },
          ];

          this.chartReady = true;

        })
    },
    unsetFilter(key) {
      this.parameters[key] = "";
      this.getMarketingOptIns();
      this.getMarketingOptInsStats();
    },
    isToday(order) {
      return moment(order.created_at).isSame(moment(), "day");
    },
    resetFilters() {
      this.parameters = {
        q: "",
        source: "",
        coupon: "",
        synchronisation_status: "",
        from: "",
        to: "",
        statuses: [],
      };
      this.appliedFilters = {};
      this.getMarketingOptIns();
      this.getMarketingOptInsStats()
    },
    getMarketingOptIns(page = 1) {
      this.marketingOptInsReady = false;
      this.output = "";
      this.marketingOptInsList = [];

      var params = {};
      // filter empty values
      Object.keys(this.parameters).forEach((key) => {
        if (this.parameters[key] != "") {
          // this.parameters[key] is an array, implode by ,
          if (Array.isArray(this.parameters[key])) {
            params[key] = this.parameters[key].join(",");
          } else {
            params[key] = this.parameters[key];
          }
        }
      });

      // return a string containing the filters and query currently applied
      this.appliedFilters = {};
      if (this.parameters.synchronisation_status) {
        this.appliedFilters["synchronisation_status"] =
          this.parameters.synchronisation_status;
      }
      if (this.parameters.source) {
        this.appliedFilters["source"] = this.parameters.source;
      }
      if (this.parameters.from) {
        this.appliedFilters["from"] = this.parameters.from;
      }
      if (this.parameters.to) {
        this.appliedFilters["to"] = this.parameters.to;
      }
      if (this.parameters.q) {
        this.appliedFilters["q"] = this.parameters.q;
      }

      this.filteredResults = {
        totalOptins: null,
        totalOptouts: null,
      };

      axios
        .get(
          "/api/admin/marketing-opt-ins?page=" + page + "&per_page=" + this.perPage,
          {
            params: params,
          }
        )
        .then((response) => {
          if (response.data.data) {
            this.filteredResults = {
              totalOptins: response.data.total_optins,
              totalOptouts: response.data.total_optouts,
            };
            this.marketingOptInsList = response.data;
          }
        })
        .finally(() => {
          this.marketingOptInsReady = true;
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.newsletter-component {
  background: #f1f5f9;
  position: relative;

  .content {
    min-height: calc(100vh - 50px);
    .table-container {
      .hidden {
        height: 0;
        overflow: hidden;
        transition: 0.2s;
        opacity: 0;
      }

      // shadow
      tbody tr {
        .status {
          font-size: 0.9em;
        }
        &:hover {
          background: #fafafa;
          &.isToday {
            background: #fafafa;
          }

          .hidden {
            height: 22px;
            transition: 0.2s;
            opacity: 1;
          }
        }
        &.isToday {
          background: #f4fbfc;
          .pale {
            color: #999;
          }
        }
      }
    }
  }
  .ucfirst {
    text-transform: capitalize;
  }
  .failedToSyncOrder {
    background: #dd4f4f8a;
    &:hover {
      background: #ea2a2ab6;
    }
  }
  .retrying {
    background: #daa5498a;
    &:hover {
      background: #eab02ab6;
    }
  }
  .filters-box {
    z-index: 4;
    transition: 0.2s;
    position: absolute;
    right: 20px;
    top: 60px;
    max-width: 500px;
    background: #f1f5f9;
    border: 1px solid #e6e6e6;
    padding: 24px;

    pointer-events: none;
    opacity: 0;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }

  .filters-applied {
    border-radius: 6px;
    padding: 5px 10px;
    display: flex;
    font-size: 14px;
    align-items: center;
    .label {
      color: #888;
      font-weight: 600;
      margin-right: 16px;
    }
    .pill {
      background: #ffc641;
      padding: 3px 10px 1px;
      border-radius: 8px;
      margin-right: 8px;
      font-size: 0.9em;
      // shadow
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
        0 1px 3px 0 rgba(0, 0, 0, 0.19);
      .unset-filter {
        cursor: pointer;
        padding-left: 8px;
      }
    }
  }

  .multiselect__tags {
    border-radius: 0px;
    border: 1px solid #ced4da;
  }

  .floating-summary {
    position: sticky;
    bottom: 0;
    left: 0;
    background: white;
    border: 1px solid #e6e6e6;
    padding: 10px;
    z-index: 3;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    color: #888;
    .pre {
      font-weight: 400;
    }
    .value {
      color: #333;
      font-weight: 600;
    }
  }

  .linechart-bg {
    padding: 10px;
    background: white;
    border-radius: 2px;
    border: 1px solid #e6e6e6;
  }
}
</style>
